let cookiesEnabled = false

const googleAnalyticsCookieNames = [
  '_ga', '_gid', '_gat',
  'AMP_TOKEN',
  '__utma', '__utmt', '__utmb', '__utmc', '__utmz', '__utmv',
  '__utmx', '__utmxx',
  '_gaexp'
]

// Helpers

const eraseCookies = (names) =>  {
  const domain = window.location.host.split(".").slice(-2).join(".")

  for (let i = 0; i < names.length; i++) {
    document.cookie = `${names[i]}=;Max-Age=0;expires=${new Date().toUTCString()};domain=.${domain};path=/`
    document.cookie = `${names[i]}=;Max-Age=0;expires=${new Date().toUTCString()};`
  }
}

// Public Api

export const cookiesAreEnabled = () => { return !!cookiesEnabled }

export const disableCookies = () => {
  cookiesEnabled = false
  // Remove cookies set by GA
  eraseCookies(googleAnalyticsCookieNames)
}

export const enableCookies = () => { cookiesEnabled = true }
