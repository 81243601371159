import { Controller } from "@hotwired/stimulus"

import { toggleCssActive } from "../src/dom"
import { EventBus } from "../src/event_bus"
import { PLAYER_EVENTS, PLAYER_STATES } from "../src/constants/player"

import PlayerBuilder from "../src/shaka-player-setup/player-builder"

const UI_HIDING_DELAY = 2_500

export default class extends Controller {
  static targets = ["bonusContentTrigger", "ctaWrapper", "playButtonText"]
  static values = {
    hideOnPause: Boolean,
    playerConfiguration: Object,
    locale: String,
  }

  // Lifecycle

  async connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) { return }

    this.currentState = PLAYER_STATES.initializing
    this.videoContainerElement = this.element.querySelector("#player-container")
    this.uiHidingTimeout = null

    this.handler = new PlayerBuilder({
      videoContainerElement: this.videoContainerElement,
      configuration: this.configuration,
      locale: this.localeValue
    })

    window.currentPlayerController = this
    window.currentPlayerHandler = this.handler
    await this.handler.start()

    this.addEventListeners()
  }

  disconnect() {
    this.removeEventListeners()
    this.handler.destroy()
  }

  // Entrypoints

  pausePlayback() { this.handler.pause() }

  async startPlayback() {
    window.clearTimeout(this.uiHidingTimeout)
    this.videoContainerElement.classList.remove("is--invisible")
    this.handler.play()
  }

  async switchBonusContent(event) {
    const trigger = event.target
    const newContentType = trigger.dataset.bonusContentType

    this.bonusContentTriggerTargets.forEach(element => toggleCssActive(element, element === trigger))

    // Start playback of the new content
    this.playButtonTextTarget.innerHTML = trigger.dataset.playButtonText
    await this.handler.loadBonusContent(newContentType)
    this.startPlayback()
  }

  // Actions

  hideCta() {
    if (!this.hasCtaWrapperTarget) { return }
    this.ctaWrapperTarget.classList.remove("is--visible")
  }

  // This is essentially a minimal state machine that handles from -> to state transitions. Once this gets more complicated, we should actually implement a proper system.
  handlePlayerStateChange() {
    const newState = this.handler.state
    if (newState == this.currentState) { return }

    switch (this.currentState) {
      case PLAYER_STATES.playing:
        switch(newState) {
          case PLAYER_STATES.finished:
          case PLAYER_STATES.error:
            this.hidePlayerOnEnd()
          break;

          case PLAYER_STATES.paused:
            window.clearTimeout(this.uiHidingTimeout)
            this.uiHidingTimeout = window.setTimeout(() => { this.hidePlayerOnPause() }, UI_HIDING_DELAY)
          break;
        }
      break;

      case PLAYER_STATES.paused:
        switch(newState) {
          case PLAYER_STATES.finished:
          case PLAYER_STATES.error:
            this.hidePlayerOnEnd()
          break;
        }
      break;

      default:
        switch(newState) {
          case PLAYER_STATES.playing:
            window.clearTimeout(this.uiHidingTimeout)
          break;
        }
    }

    this.currentState = newState
  }

  hidePlayerOnEnd() {
    if (document.fullscreenElement) {
      document
        .exitFullscreen()
        .then(() => this.hidePlayer())
      return
    }

    this.hidePlayer()
  }

  // This is a special case of hidePlayer() that makes sure we are not in full screen mode before hiding the player. If we are in full screen, the check is repeated later on again.
  hidePlayerOnPause() {
    if (this.currentState != PLAYER_STATES.paused) { return }

    if (document.fullscreenElement) {
      window.clearTimeout(this.uiHidingTimeout)
      this.uiHidingTimeout = window.setTimeout(() => { this.hidePlayerOnPause() }, UI_HIDING_DELAY)
      return
    }

    this.hidePlayer()
  }

  hidePlayer() {
    if (!this.hideOnPauseValue) { return }

    this.videoContainerElement.classList.add("is--invisible")
  }

  showCta() {
    if (!this.hasCtaWrapperTarget) { return }
    this.ctaWrapperTarget.classList.add("is--visible")
  }

  showErrorMessage(_event) {
    console.log("Error event received from shaka-player")
  }

  // Setup helpers

  addEventListeners() {
    this.boundCtaDisplayHandler = EventBus.on(PLAYER_EVENTS.ctaTimestampReached, () => { this.showCta() })
    this.boundErrorHandler = EventBus.on(PLAYER_EVENTS.error, (event) => { this.showErrorMessage(event) })
    this.boundStateChangeHandler = EventBus.on(PLAYER_EVENTS.stateChanged, () => { this.handlePlayerStateChange() })
    this.boundPauseHandler = EventBus.on(PLAYER_EVENTS.pause, () => { this.pausePlayback() })
    this.boundPlayHandler = EventBus.on(PLAYER_EVENTS.play, () => { this.startPlayback() })
  }

  removeEventListeners() {
    EventBus.off(PLAYER_EVENTS.ctaTimestampReached, this.boundCtaDisplayHandler)
    EventBus.off(PLAYER_EVENTS.error, this.boundErrorHandler)
    EventBus.off(PLAYER_EVENTS.stateChanged, this.boundStateChangeHandler)
    EventBus.off(PLAYER_EVENTS.pause, this.boundPauseHandler)
    EventBus.off(PLAYER_EVENTS.play, this.boundPlayHandler)
  }

  // Getters

  get configuration() { return this.playerConfigurationValue }
}
