import { generateUUID } from "../uuid"
import { CONFIGURATION_TYPES, MOVIE_TYPES } from "../constants/player"

export const buildPlaylist = (configuration) => {
  let playlist = [mainPlaylistEntry(configuration)]

  if (configuration.preRollURL) {
    playlist.unshift({
      image: configuration.screenShotURL,
      movieType: MOVIE_TYPES.preRoll,
      movieUrl: configuration.preRollURL,
      mediaId: generateUUID(),
      showCta: false,
      trackPlaybackPosition: false,
    })
  }

  return playlist
}

const mainPlaylistEntry = (configuration) => {
  return addDrmConfiguration(configuration, {
    image: configuration.screenShotURL,
    initialPlaybackPosition: configuration.initialPlaybackPosition,
    matomoTitle: configuration.title,
    mediaId: generateUUID(),
    movieType: configuration.movieType,
    title: configuration.title,
    // Signed content URL
    movieUrl: configuration.movieURL,
    // Tracking URLs
    moviePlayCountingUrl: configuration.moviePlayCountingURL,
    orderViewCountingUrl: configuration.orderViewCountingURL,
    playbackPositionUrl: configuration.playbackPositionURL,
    trailerViewCountingUrl: configuration.trailerViewCountingURL,
    // Potential DRM
    drm: {},
    // Flags
    ctaTimestampInSeconds: isMainMovie(configuration) && configuration.ctaTimestampInSeconds,
    trackPlaybackPosition: isMainMovie(configuration),
  })
}

const addDrmConfiguration = (configuration, playlistEntry) => {
  if (configuration.playreadyURL) {
    playlistEntry.drm.playready = { url: configuration.playreadyURL }
  }

  if (configuration.widevineURL) {
    playlistEntry.drm.widevine = { url: configuration.widevineURL }
  }

  if (configuration.fairplay) {
    playlistEntry.hlsMovieUrl = configuration.fairplay.url

    if (isUsingFairplayDrm(configuration.fairplay)) {
      playlistEntry.drm.fairplay = {
        licenseUrl: configuration.fairplay.processSpcUrl,
        certificateUrl: configuration.fairplay.certificateUrl
      }
    }
  }

  return playlistEntry
}

const isMainMovie = (configuration) => {
  switch (configuration.configurationType) {
    case CONFIGURATION_TYPES.drm:
    case CONFIGURATION_TYPES.multiStream:
    case CONFIGURATION_TYPES.s3:
      return true
    case CONFIGURATION_TYPES.landingpage:
    case CONFIGURATION_TYPES.externalHls:
    case CONFIGURATION_TYPES.trailer:
      return false
  }
}

const isUsingFairplayDrm = (fairplayConfig) => {
  return fairplayConfig?.processSpcUrl && fairplayConfig?.certificateUrl
}
