/**
 * Shorthand for element.classlist.toggle("is--active", <condition>)
 * @param {Node} element The DOM element to operate on
 * @param {Boolean} condition The condition that defines whether to add or hide the CSS class
 * @return {void}
 */
export const toggleCssActive = (element, condition) => {
  element.classList.toggle("is--active", condition)
}

export const addClass = (element, className) => { element?.classList?.add(className) }
export const removeClass = (element, className) => { element?.classList?.remove(className) }

export const findOrReturnElement = (selectorStringOrDomNode) => {
  if (typeof selectorStringOrDomNode === "string") { return document.querySelector(selectorStringOrDomNode) }

  return selectorStringOrDomNode
}
