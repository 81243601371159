import { Controller } from "@hotwired/stimulus"

const CSS_CLASSES = {
  enabled: "is--enabled",
  hidden: "is--hidden"
}

const cookieName = "enableEmbedding"

const isEmbeddingEnabled = () => {
  return document.cookie.split(";").some(function(item) {
    return item.trim().indexOf(cookieName + "=") == 0
  })
}

const addCookie = () => {
  const expiryDate = new Date()
  expiryDate.setFullYear(expiryDate.getFullYear() + 1)

  document.cookie = `${cookieName}=true; expires=${expiryDate.toUTCString()}`
}

const removeCookie = () => { document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT` }

export default class extends Controller {
  static targets = ["playerWrapper", "privacyPlaceholder"]
  static values = { configuration: Object }

  async connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) { return }

    this.configuration = this.configurationValue
    if (!this.configuration?.youTubeVideoId) { return }

    if (isEmbeddingEnabled()) { this.enableEmbedding() }
  }

  disconnect() { window.onYouTubeIframeAPIReady = null }

  // Actions

  disableEmbedding() {
    removeCookie()

    if (typeof Turbo !== "undefined") {
      Turbo.visit(window.location.href)
    } else {
      window.location.reload()
    }
  }

  enableEmbedding() {
    addCookie()
    this.hidePrivacyPlaceholder()
    this.showPlayerWrapper()
    this.addCallbacks()
    this.setupPlayer()
  }

  // Interface

  hidePrivacyPlaceholder() {
    if (!this.hasPrivacyPlaceholderTarget) { return }
    this.privacyPlaceholderTarget.classList.add(CSS_CLASSES.hidden)
  }

  showPlayerWrapper() {
    if (!this.hasPlayerWrapperTarget) { return }
    this.playerWrapperTarget.classList.add(CSS_CLASSES.enabled)
  }

  // Actual YouTube integration

  setupPlayer() {
    const firstScriptTag = document.getElementsByTagName("script")[0]
    const newScriptTag = document.createElement("script")

    newScriptTag.src = "https://www.youtube.com/iframe_api"
    firstScriptTag.parentNode.insertBefore(newScriptTag, firstScriptTag)
  }

  addCallbacks() {
    const youTubeVideoId = this.configuration.youTubeVideoId

    // We need a globally available callback function that is called once YouTube's iframe has loaded.
    const callback = () => {
      new YT.Player("player", {
        height: "390",
        width: "640",
        videoId: youTubeVideoId,
        playerVars: {
          modestbranding: 1,
          rel: 0,
        },
      })
    }

    if (window.YT) {
      callback()
    } else {
      window.onYouTubeIframeAPIReady = callback
    }
  }
}
