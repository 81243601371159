import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["image"]

  connect() {
    this.initialized = false

    if (!this.imageTargets[0]) { return }
    if (this.imageTargets[0].complete) { this.setSliderHeight() }

    this.imageTargets[0].addEventListener("load", () => { this.setSliderHeight() })
  }

  setSliderHeight() {
    this.element.style.height = `${this.imageTargets[0].offsetHeight}px`
    this.initialized = true
  }
}
