export const CONFIGURATION_TYPES = {
  drm: "drm",
  externalHls: "external_hls",
  landingpage: "landingpage",
  multiStream: "multi_stream",
  s3: "s3",
  trailer: "trailer"
}

export const MOVIE_TYPES = {
  movie: "movie",
  trailer: "trailer",
  preRoll: "pre_roll"
}

export const PLAYER_EVENTS = {
  ctaTimestampReached: "player:ctaTimestampReached",
  error: "player:error",
  loaded: "player:loaded",
  pause: "player:stop",
  play: "player:play",
  stateChanged: "player:stateChanged"
}

export const PLAYER_STATES = {
  error: "error",
  initializing: "initializing",
  finished: "finished",
  paused: "paused",
  playing: "playing",
  ready: "ready",
  unsupported: "unsupported",
}
