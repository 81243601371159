let cachedFetchConfigPOST

export const getCsrfToken = () => {
  return document.querySelector("meta[name=csrf-token]")?.content
}

export const fetchHeaderConfiguration = () => {
  return {
    "Content-Type": "application/json",
    "Accept": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "X-CSRF-TOKEN": getCsrfToken(),
  }
}

export const fetchConfigPOST = () => {
  if (cachedFetchConfigPOST) { return cachedFetchConfigPOST }

  cachedFetchConfigPOST = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": getCsrfToken()
    }
  }

  return cachedFetchConfigPOST
}
