import { isUsingSafari } from "../browser-detection"

export const getIdealMovieUrl = (playlistItem) => {
  // Easy cases: Only one of the two URL exists.
  if (playlistItem.movieUrl && !playlistItem.hlsMovieUrl) {
    return playlistItem.movieUrl
  } else if (!playlistItem.movieUrl && playlistItem.hlsMovieUrl) {
    return playlistItem.hlsMovieUrl
  }

  // If the User is on Safari, we always want to use the HLS stream.
  if (isUsingSafari()) { return playlistItem.hlsMovieUrl }

  return playlistItem.movieUrl
}

export const isDrmProtected = (playlistItem) => {
  const drmData = playlistItem.drm
  if (!drmData) { return false }

  if (drmData?.widevine?.url || drmData?.playready?.url) { return true }
  if (isUsingFairplay(playlistItem)) { return true }

  return false
}

export const isUsingFairplay = (playlistItem) => {
  const drmData = playlistItem.drm
  if (!drmData) { return false }

  return (drmData?.fairplay?.licenseUrl && drmData?.fairplay?.certificateUrl)
}
