import { cookiesAreEnabled } from "../cookies"

const MAX_CHECKED_GA_RETRIES = 10
const GOOGLE_ANALYTICS_TYPE_UA = "UA"
const GOOGLE_ANALYTICS_TYPE_GA4 = "GA4"

let checked_ga_retries = 0
let disabledGoogleAnalyticsTrackers = []
let googleAnalyticsId = null
let googleAnalyticsType = null
let googleAnalyticsInitialized = false
let googleTagManagerId = null
let googleTagManagerInitialized = false

// Interhal helpers

const getOptOutString = () => { return `ga-disable-${googleAnalyticsId}` }

const setGoogleAnalyticsDisableVariable = (googleAnalyticsId) => {
  const gaIdString = `ga-disable-${googleAnalyticsId}`
  disabledGoogleAnalyticsTrackers.push(gaIdString)
  window[gaIdString] = true
}

const loadAnalyticsScript = (i, s, o, g, r, a, m) => {
  i["GoogleAnalyticsObject"] = r;
  i[r] = i[r] || function () {
    (i[r].q = i[r].q || []).push(arguments)
  }, i[r].l = 1 * new Date();
  a = s.createElement(o),
    m = s.getElementsByTagName(o)[0];
  a.async = 1;
  a.src = g;
  m.parentNode.insertBefore(a, m)
}

const loadGA4AnalyticsScript = (win, doc, url) => {
  const scriptTag = doc.createElement("script")
  const parent = doc.getElementsByTagName("script")[0]

  scriptTag.async = 1
  scriptTag.src = url

  parent.parentNode.insertBefore(scriptTag, parent)
  window.dataLayer = window.dataLayer || [];
  window.gtag = window.gtag || function() { dataLayer.push(arguments); }
}

const loadGtmScript = (w,d,s,l,i) => {
  w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
}

// Public API

export const setGoogleAnalyticsId = (value) => {
  googleAnalyticsId = value

  if (googleAnalyticsId.startsWith("UA")) {
    googleAnalyticsType = GOOGLE_ANALYTICS_TYPE_UA
  } else {
    googleAnalyticsType = GOOGLE_ANALYTICS_TYPE_GA4
  }
}

export const setGoogleTagManagerId = (value) => { googleTagManagerId = value }

export const checked_ga = (a, b, c, d, e) => {
  // If the user has denied cookies, we don't attempt to send any tracking events to Google.
  if (!cookiesAreEnabled()) { return }

  checked_ga_retries += 1
  if (checked_ga_retries > MAX_CHECKED_GA_RETRIES) { return }

  const functionName = googleAnalyticsType == GOOGLE_ANALYTICS_TYPE_UA ? "ga" : "gtag"

  if (typeof window[functionName] === "function") {
    window[functionName](a, b, c, d, e)
    checked_ga_retries = 0
  } else {
    window.setTimeout(checked_ga, 500, a, b, c, d, e)
  }
}

export const gtag = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(arguments)
}

export const disableAllGoogleTrackers = () => {
  try {
    for(let i = 0; i < ga.getAll().length; i++) {
      setGoogleAnalyticsDisableVariable(ga.getAll()[i].get("trackingId"))
    }
  } catch (error) {}
}

export const enableAllGoogleTrackers = () => {
  try {
    for(let i = 0; i < disabledGoogleAnalyticsTrackers.length; i++) {
      const gaIdString = disabledGoogleAnalyticsTrackers[i]
      delete window[gaIdString]
    }
  } catch (error) {}
}

// Disable tracking if the opt-out cookie exists.
export const honorExistingOptOut = () => {
  const cookieComparison = `${getOptOutString()}=true`
  if (document.cookie.indexOf(cookieComparison) > -1) { window[getOptOutString()] = true }
}

export const optOut = () => {
  const cookieData = `${getOptOutString()}=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/`
  document.cookie = cookieData
  window[getOptOutString()] = true
}

export const startGoogleAnalytics = () => {
  // We gate this call, as the user might enable and disable cookies/tracking multiple times on the same page.
  if (googleAnalyticsInitialized) { return }
  if (!googleAnalyticsId) { return }

  if (googleAnalyticsType == GOOGLE_ANALYTICS_TYPE_UA) {
    // Legacy UA.
    loadAnalyticsScript(window, document, "script", "https://www.google-analytics.com/analytics.js", "ga")
    checked_ga("create", googleAnalyticsId, "auto")
    checked_ga("set", "anonymizeIp", true)
    checked_ga("send", "pageview")
  } else {
    // New GA4 property.
    loadGA4AnalyticsScript(window, document, `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`)
    checked_ga("js", new Date())
    checked_ga("config", googleAnalyticsId)
  }

  googleAnalyticsInitialized = true
}

export const startGTM = () => {
  if (googleTagManagerInitialized) { return }
  if (!googleTagManagerId) { return }

  loadGtmScript(window, document, 'script', 'dataLayer', googleTagManagerId)

  googleTagManagerInitialized = true
}
