// This is a JSified version of mitt that returns the just-added handler method when calling .on().
// The idea behind this is to prevent the need for repeated code in the style of:
//
// setupHandlers() {
//   this.boundFn = this.fn.bind(this)
//   EventBus.on("event", this.boundFn)
// }
//
// removeHandlers() {
//   EventBus.off("event", this.boundFn)
// }
//
// Instead, we can now write:
//
// setupHandlers() {
//   this.boundFn = EventBus.on("event", (event) => { this.fn(event) })
// }
//
// removeHandlers() {
//   EventBus.off("event", this.boundFn)
// }
//
// The original mitt source is available at https://github.com/developit/mitt
/**
* Mitt: Tiny (~200b) functional event emitter / pubsub.
* @name mitt
* @returns {Mitt}
*/
const mitt = (all) => {
	all = all || new Map();

	return {
		/**
		* A Map of event names to registered handler functions.
		*/
		all,

		/**
		* Register an event handler for the given type.
		* @param {string|symbol} type Type of event to listen for, or `'*'` for all events
		* @param {Function} handler Function to call in response to given event
		* @memberOf mitt
    * @returns {Function} the function that was just added as a handler
		*/
		on(type, handler) {
			const handlers = all.get(type);
			if (handlers) {
				handlers.push(handler);
			} else {
				all.set(type, [handler]);
			}

      return handler
		},

		/**
		* Remove an event handler for the given type.
		* If `handler` is omitted, all handlers of the given type are removed.
		* @param {string|symbol} type Type of event to unregister `handler` from (`'*'` to remove a wildcard handler)
		* @param {Function} [handler] Handler function to remove
		* @memberOf mitt
		*/
		off(type, handler) {
			const handlers = all.get(type);
			if (handlers) {
				if (handler) {
					handlers.splice(handlers.indexOf(handler) >>> 0, 1);
				} else {
					all.set(type, []);
				}
			}
		},

		/**
		* Invoke all handlers for the given type.
		* If present, `'*'` handlers are invoked after type-matched handlers.
		*
		* Note: Manually firing '*' handlers is not supported.
		*
		* @param {string|symbol} type The event type to invoke
		* @param {Any} [evt] Any value (object is recommended and powerful), passed to each handler
		* @memberOf mitt
		*/
		emit(type, evt) {
			let handlers = all.get(type);
			if (handlers) {
				handlers
				.slice()
				.map((handler) => {
					handler(evt);
				});
			}

			handlers = all.get('*');
			if (handlers) {
				handlers
				.slice()
				.map((handler) => {
					handler(type, evt);
				});
			}
		}
	};
}

export const EventBus = mitt()
