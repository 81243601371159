import { Controller } from "@hotwired/stimulus"
import { EventBus } from "../src/event_bus"
import { PLAYER_EVENTS } from "../src/constants/player"

export default class extends Controller {
  static targets = ["trailerWrapper"]

  connect() {
    this.isOpen = false
    this.boundEscHandler = this.handleKeyPress.bind(this)
  }

  disconnect() { this.removeEventListeners() }

  closeTrailer(event, forceClose) {
    if (!this.isOpen) { return }
    if (!forceClose && event?.target?.closest(".kino-plus-player")) { return }

    EventBus.emit(PLAYER_EVENTS.pause)
    this.trailerWrapperTarget.classList.remove("is--open")
    this.removeEventListeners()
  }

  forceCloseTrailer(event) { this.closeTrailer(event, true) }

  handleKeyPress(event) {
    if (event.key !== "Escape") { return }
    this.forceCloseTrailer()
  }

  showTrailer() {
    if (!this.hasTrailerWrapperTarget) { return }

    this.trailerWrapperTarget.classList.add("is--open")
    this.isOpen = true
    this.addEventListeners()

    window.setTimeout(() => { EventBus.emit(PLAYER_EVENTS.play) }, 250)
  }

  addEventListeners() { document.addEventListener("keydown", this.boundEscHandler) }
  removeEventListeners() { document.removeEventListener("keydown", this.boundEscHandler) }
}
