import { isDrmProtected, isUsingFairplay } from "./helpers"

export const buildDrmConfiguration = async (playlistItem) => {
  const drmData = playlistItem.drm
  const playerDrmConfig = {}
  if (!isDrmProtected(playlistItem)) { return playerDrmConfig }

  playerDrmConfig.servers = {
    "com.widevine.alpha": drmData.widevine.url,
    "com.microsoft.playready": drmData.playready.url
  }

  if (isUsingFairplay(playlistItem)) {
    const req = await fetch(drmData.fairplay.certificateUrl)
    const cert = await req.arrayBuffer()

    playerDrmConfig.servers["com.apple.fps.1_0"] = drmData.fairplay.licenseUrl
    playerDrmConfig.advanced = {
      "com.apple.fps.1_0": {
        serverCertificate: new Uint8Array(cert)
      }
    }
  }

  return playerDrmConfig
}
