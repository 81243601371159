// Stimulus
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
// Various polyfills
import "../src/polyfills"

// Stimulus controllers
window.Stimulus = window.Stimulus || Application.start()

Stimulus.load(definitionsFromContext(require.context("../player_controllers", true, /\.js$/)))
