// If running in local dev, change this to "shaka-player/dist/shaka-player.ui.debug"
import shaka from "shaka-player/dist/shaka-player.ui"

import { MOVIE_TYPES, PLAYER_EVENTS, PLAYER_STATES } from "../constants/player"
import { checked_ga } from "../analytics/google"
import { isUsingSafari } from "../browser-detection"
import { findOrReturnElement } from "../dom"
import { EventBus } from "../event_bus"
import { fetchConfigPOST } from "../networking"

import { buildDrmConfiguration } from "./drm-configuration"
import { buildPlaylist } from "./playlist"
import { getIdealMovieUrl } from "./helpers"

const DEFAULT_CONTROL_PANEL_ELEMENTS = [
  "play_pause",
  "time_and_duration",
  "spacer",
  "mute",
  "volume",
  "fullscreen",
  "overflow_menu",
]

// We're considering 95% completion to be completely finished.
const PLAYBACK_POSITION_END_DETECTION_PERCENTAGE = 0.95

export default class {
  playbackPositionIntervalId = null
  playbackPositionIntervalDelta = 45000

  // This list is used to track which movies/trailers/bonus contents/PreRolls we have already started once. This way we can easily track which events still need to be sent.
  startedMediaIds = []
  ctaTimestampReachedForCurrentPlaylistItem = false

  currentPlaylistIndex = null
  currentPlaylistItem = null

  // The actual player we're going to build.
  player = null
  ui = null

  constructor({ videoContainerElement, configuration, locale }) {
    // Let the world know we're starting to do something.
    this.changeState(PLAYER_STATES.initializing)

    // Install Shaka's built-in polyfills to patch browser incompatibilities.
    shaka.polyfill.installAll()
    if (isUsingSafari()) { shaka.polyfill.PatchedMediaKeysApple.install() }

    // Start the actual setup
    this.configuration = configuration
    this.locale = locale
    this.playlist = buildPlaylist(configuration)
    this.videoContainerElement = findOrReturnElement(videoContainerElement)
    this.videoElement = this.videoContainerElement.querySelector("#player")
  }

  //
  // Main entry points
  //

  async start() {
    // If the browser doesn't support our player, we don't have to do anything.
    if (!shaka.Player.isBrowserSupported()) {
      this.changeState(PLAYER_STATES.unsupported)
      return
    }

    await this.buildPlayer()
    await this.loadPlaylistItem(0)
  }

  async loadBonusContent(bonusContentType) {
    // Prevent restarting the current bonus content item by clicking the trigger multiple times.
    const currentContentType = this.currentPlaylistItem?.movieType
    if (bonusContentType == currentContentType) { return }

    const bonusContentConfiguration = this.configuration.bonusContent.find(entry => entry.trigger === bonusContentType)
    if (!bonusContentConfiguration) { return }

    const newTitle = this.bonusContentMatomoTitle(bonusContentType, this.configuration.title)

    // TODO: Handle bonus content without a trailer (hide the screenshot, initialize/show the player, etc.)

    this.playlist = [{
      ...bonusContentConfiguration,
      title: newTitle,
      matomoTitle: newTitle,
    }]

    await this.loadPlaylistItem(0)
  }

  play() {
    switch (this.state) {
      case PLAYER_STATES.finished:
      case PLAYER_STATES.paused:
      case PLAYER_STATES.ready:
        this.videoElement.play()
    }
  }

  pause() {
    if (this.state !== PLAYER_STATES.playing) { return }

    this.videoElement.pause()
  }

  async destroy() {
    await this.player.destroy()
  }

  //
  // Player setup
  //

  async buildPlayer() {
    // "local" because it is for browser-local playback only, as opposed to the player proxy object, which will route calls to the potential ChromeCast receiver as necessary. Once we have initialized the proxy object, we don't need the local instance anymore.
    const localPlayer = new shaka.Player()
    this.ui = new shaka.ui.Overlay(localPlayer, this.videoContainerElement, this.videoElement)
    await localPlayer.attach(this.videoElement)

    // These are cast-enabled proxy objects, so that when we are casting, the method calls will be routed to the remote playback session. Otherwise the localPlayer will be controlled.
    const controls = this.ui.getControls()
    this.player = controls.getPlayer()
    this.video = controls.getVideo()

    // DRM configuration is specific to each item in the playlist, so it will be handled in loadPlaylistItem().
    this.configureUi()
    this.configureEvents()

    // Attach the player to the UI/wrapper we've declared so it's actually usable.
    await this.player.attach(this.videoElement)
  }

  async configureDrm() {
    const config = await buildDrmConfiguration(this.currentPlaylistItem)
    this.player.configure({ drm: config })
  }

  // This configures various handlers for Events emitted by the player. Whether we actually do something in the handlers in reaction to these events depends on our current playlist entry, which carries various flags that differ between trailer/movie/preRoll/etc.
  configureEvents() {
    this.player.addEventListener("error", (event) => { this.handlePlayerError(event.detail) })

    this.setupCompletionCallback()
    this.setupCtaDisplay()
    this.setupMoviePlayTracking()
    this.setupPlaybackPositionTracking()
    this.setupPlayerStateTracking()
  }

  // TODO: Check if we want to dynamically adapt this for e.g. PreRolls.
  configureUi() {
    this.ui.configure({
      controlPanelElements: DEFAULT_CONTROL_PANEL_ELEMENTS,
      trackLabelFormat: shaka.ui.Overlay.TrackLabelFormat.LABEL,
      textTrackLabelFormat: shaka.ui.Overlay.TrackLabelFormat.LABEL
    })

    if (this.locale) { this.ui.getControls().getLocalization().changeLocale([this.locale]) }
  }

  setupCompletionCallback() {
    if(typeof window.playbackCompletionCallback !== "function") { return }
    this.videoElement.addEventListener("ended", () => { window.playbackCompletionCallback() })
  }

  setupCtaDisplay() {
    this.videoElement.addEventListener("timeupdate", (event) => {
      if (!this.currentPlaylistItem.ctaTimestampInSeconds) { return }
      if (this.ctaTimestampReachedForCurrentPlaylistItem) { return }
      if (this.videoElement.currentTime < this.currentPlaylistItem.ctaTimestampInSeconds) { return }

      EventBus.emit(PLAYER_EVENTS.ctaTimestampReached)
      this.ctaTimestampReachedForCurrentPlaylistItem = true
    })
  }

  setupMoviePlayTracking() {
    this.videoElement.addEventListener("play", () => {
      // We only want to count the initial play action for trailers/movies, none afterwards.
      if (this.startedMediaIds.includes(this.currentPlaylistItem.mediaId)) { return }
      this.startedMediaIds.push(this.currentPlaylistItem.mediaId)

      switch (this.currentPlaylistItem.movieType) {
        case MOVIE_TYPES.trailer:
          this.countTrailerView()
          break;

        case MOVIE_TYPES.movie:
          this.countMoviePlay()
          this.increaseOrderViews()
          this.sendGoogleAnalyticsPing()
      }
    })
  }

  setupPlaybackPositionTracking() {
    this.videoElement.addEventListener("play", () => {
      // We immediately fire this once to capture 0-second events and prevent weirdnesses when pausing/playing a lot, thus delaying the regular interval indefinitely.
      this.saveLastPlaybackPosition()

      window.clearInterval(this.playbackPositionIntervalId)

      this.playbackPositionIntervalId = window.setInterval(
        () => { this.saveLastPlaybackPosition() },
        this.playbackPositionIntervalDelta
      )
    })

    this.videoElement.addEventListener("pause", () => {
      window.clearInterval(this.playbackPositionIntervalId)
      this.saveLastPlaybackPosition()
    })

    this.videoElement.addEventListener("ended", () => {
      window.clearInterval(this.playbackPositionIntervalId)
      this.saveLastPlaybackPosition()
    })
  }

  setupPlayerStateTracking() {
    this.player.addEventListener("loaded", () => { this.changeState(PLAYER_STATES.ready) })
    this.videoElement.addEventListener("play", () => { this.changeState(PLAYER_STATES.playing) })
    this.videoElement.addEventListener("pause", () => { this.changeState(PLAYER_STATES.paused) })

    // We only switch to "ended" if there are no more items in the playlist. If there are, we silently switch to the next one and keep playing.
    this.videoElement.addEventListener("ended", async () => {
      if (this.hasNextPlayistItem()) {
        await this.loadPlaylistItem(this.currentPlaylistIndex + 1)
        this.play()
      } else {
        this.changeState(PLAYER_STATES.finished)
      }
    })
  }

  //
  // Internal state changes
  //

  changeState(newState) {
    this.state = newState
    EventBus.emit(PLAYER_EVENTS.stateChanged)
  }

  async loadPlaylistItem(index) {
    this.currentPlaylistIndex = index
    this.currentPlaylistItem = this.playlist[index]
    this.ctaTimestampReachedForCurrentPlaylistItem = false
    if (!this.currentPlaylistItem) { return }

    await this.configureDrm()
    await this.player.load(
      getIdealMovieUrl(this.currentPlaylistItem),
      this.currentPlaylistItem.initialPlaybackPosition
    )

    EventBus.emit(PLAYER_EVENTS.loaded)
  }

  // Callbacks / AJAX requests

  countMoviePlay() {
    if (!this.currentPlaylistItem.moviePlayCountingUrl) { return }
    fetch(this.currentPlaylistItem.moviePlayCountingUrl, fetchConfigPOST())
  }

  countTrailerView() {
    if (!this.currentPlaylistItem.trailerViewCountingUrl) { return }
    fetch(this.currentPlaylistItem.trailerViewCountingUrl, fetchConfigPOST())
  }

  increaseOrderViews() {
    if (!this.currentPlaylistItem.orderViewCountingUrl) { return }
    fetch(this.currentPlaylistItem.orderViewCountingUrl, fetchConfigPOST())
  }

  saveLastPlaybackPosition() {
    if (!this.currentPlaylistItem.playbackPositionUrl) { return }
    if (!this.currentPlaylistItem.trackPlaybackPosition) { return }

    // If we are close to the end, we reset the position to 0 so subsequent plays start from the beginning.
    let currentTime = this.videoElement.currentTime
    let duration = this.videoElement.duration
    let percentageCompleted = currentTime / duration

    if (percentageCompleted >= PLAYBACK_POSITION_END_DETECTION_PERCENTAGE) { currentTime = 0 }

    fetch(this.currentPlaylistItem.playbackPositionUrl, {
      ...fetchConfigPOST(),
      body: JSON.stringify({ last_playback_position: currentTime })
    })
  }

  sendGoogleAnalyticsPing() {
    if (!this.currentPlaylistItem.googleAnalyticsParameter) { return }
    checked_ga("send", "event", "Movies", "play trailer", this.currentPlaylistItem.googleAnalyticsParameter)
  }

  // Helpers

  bonusContentMatomoTitle(trigger) {
    let movieTitle = this.configuration.title

    // If we're watching a trailer, the Movie title already is prefixed with "TRAILER: ", so we remove that part.
    if (movieTitle.startsWith("TRAILER: ")) { movieTitle = movieTitle.replace("TRAILER: ", "") }

    return trigger.toUpperCase() + ": " + movieTitle
  }

  hasNextPlayistItem() {
    return !!this.playlist[this.currentPlaylistIndex + 1]
  }

  // Error handling

  handlePlayerError(error) {
    this.changeState(PLAYER_STATES.error)
    EventBus.emit(PLAYER_EVENTS.error)

    console.error("Error code", error.code, "object", error)
  }
}
